import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { LocalStorageService } from "src/app/core/util-services/local-storage.service";

@Directive({ selector: "[hasRole]" })
export class HasRoleDirective {
    constructor(
        private readonly templateRef: TemplateRef<any>,
        private readonly viewContainer: ViewContainerRef,
        private readonly storage: LocalStorageService,
    ) {}

    hasView = false;

    @Input() set hasRole(allowedRoles: string[]) {

        const userRoles = this.storage.getItem("userRole") as string[];
        const canViewComponent = userRoles.some((role) => allowedRoles.includes(role));

        if (canViewComponent && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
            return;
        }

        this.viewContainer.clear();
        this.hasView = false;
    }
}
